.main {
  // background: linear-gradient(#49263e, #8c4d5d);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 99dvh;
  padding: 0 60px 60px 60px;
  background-position-y: 30%;
  background-position-x: 30%;
  position: relative;

  .main-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url("../../assets/skks-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      // background: linear-gradient(#49263e, #8c4d5d);
      background-color: #000;
      opacity: 0.67;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }

  .tag-line {
    color: #fff;
    font-size: 22px;
    line-height: 1.2;
    max-width: 70%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: "Tilda Sans";
    .heading {
      margin-bottom: 38px;
      font-size: 68px;
      font-weight: 600;
    }
  }
}

.goal {
  min-height: 80dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  .heading {
    font-size: 32px;
    text-align: center;
  }
  .content {
    margin: auto;
    text-align: center;
    margin-top: 70px;
    font-size: 20px;
    font-weight: 300;
    line-height: 1.8;
    width: 70%;
  }
}

.down_arrow {
  position: absolute;
  top: 85%;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s ease-in-out infinite;
  cursor: pointer;
  svg {
    transform: rotate(90deg) scale(6);
    stroke: #ffffff;
  }
}

@keyframes bounce {
  0% {
    top: 85%;
  }
  50% {
    top: 86%;
  }
  100% {
    top: 85%;
  }
}

@media (max-width: 600px) {
  .main {
    // background: linear-gradient(#49263e, #8c4d5d);

    padding: 0 20px 20px 20px;
    background-position-y: 30%;
    background-position-x: 30%;

    .tag-line {
      color: #fff;
      font-size: 18px;
      line-height: 1.2;
      max-width: 70%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: "Tilda Sans";
      .heading {
        margin-bottom: 38px;
        font-size: 40px;
        font-weight: 600;
        line-height: 1;
      }
    }
  }

  .goal {
    padding: 20px;

    .heading {
      font-size: 32px;
      // text-align: start;
    }
    .content {
      margin-top: 40px;
      font-size: 16px;
      // text-align: start;
      width: 100%;
    }
  }
}
