.services-section {
  padding: 60px 100px;
  background-color: #f5f5f5;

  .main-content {
    margin-top: 50px;
    .cards {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 30px;
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 600px) {
  .services-section {
    padding: 20px;
    text-align: center;

    .main-content {
      margin-top: 50px;
      .cards {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        gap: 30px;
        flex-wrap: wrap;
      }
    }
  }
}
