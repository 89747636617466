@import "../../constants.scss";

.nav-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  background-color: $primary-blue;
  color: $white;
  .logo-wrapper img {
    max-width: 35px;
  }
  .close-menu,
  .open-menu {
    border-bottom: 1px solid $primary-bg;
    padding-bottom: 5px;
    font-size: 24px;
    cursor: pointer;
  }
  .menu {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 100%;
    transition: all 400ms linear;
    padding: 20px 50px;
    background-color: $primary-blue;
    color: $white;
    &.open {
      left: 0;
    }
    .menu-content {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 20% 0;
      .menu-item {
        font-size: 48px;
        font-weight: bold;
        cursor: pointer;
        position: relative;
        .mobile-arrow {
          display: none;
        }
        .sub-menu {
          opacity: 0;
          visibility: hidden;
          position: absolute;
          top: 120%;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          flex-direction: column;
          transition: 0.3s all ease-in-out;
          padding: 48px 0 0;
          width: 100%;
          .sub-menu-item {
            transition: 0.3s all ease-in-out;
            font-size: 24px;
            font-weight: 500;
            margin-top: 30px;
            color: #6c7389;
            white-space: nowrap;
          }
        }
        &:hover {
          color: $primary-bg;
          .sub-menu {
            opacity: 1;
            visibility: visible;
            top: 100%;
            .sub-menu-item {
              &:hover {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .nav-wrapper {
    padding: 30px 35px;
    .logo-wrapper {
      max-width: 65px;
    }
    .close-menu,
    .open-menu {
      font-size: 12px;
    }
    .menu {
      padding: 30px 35px;

      .menu-content {
        flex-direction: column;
        .menu-item {
          font-size: 24px;
          margin-bottom: 15px;
          position: relative;
          width: 100%;
          text-align: center;
          color: $primary-bg;
          .mobile-arrow {
            display: block;
            transition: 0.3s all ease-in-out;
            transform: rotate(0);
            position: absolute;
            left: 100%;
            top: 0;
          }
          .sub-menu {
            opacity: 0;
            visibility: hidden;
            position: static;
            padding: 0;
            align-items: center;
            height: 0;
            overflow: hidden;
            transition: 0.3s all ease-in-out;
            padding: 0;
            .sub-menu-item {
              font-size: 16px;
            }
          }

          &.open {
            .mobile-arrow {
              transform: rotate(180deg);
            }
            .sub-menu {
              opacity: 1;
              visibility: visible;
              height: 100%;
              padding: 10px 0;
              .sub-menu-item {
                &:hover {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (min-width: 800px) {
  .nav-wrapper {
    padding: 20px 40px;

    .close-menu,
    .open-menu {
      font-size: 20px;
    }
    .menu {
      padding: 20px 40px;

      .menu-content {
        margin: 50% 0;
        .menu-item {
          font-size: 32px;
        }
      }
    }
  }
}
