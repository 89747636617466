.card {
  max-width: 300px;
  background-color: #f5f5f5;
  padding: 30px;
  text-align: center;
  border-radius: 2px;
  transition: all 200ms linear;
  scale: 1;
  cursor: pointer;
  .heading {
    margin-top: 10px;
    font-size: 24px;
    text-decoration: underline;
    text-decoration-color: #fff;
    font-weight: 400;
    color: #1e3a5f;
  }
  .content {
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.4;
    color: #333333;
  }
  .price {
    margin-top: 10px;
    font-size: 18px;
    line-height: 1.7;
    font-weight: 400;
  }
  &:hover {
    box-shadow: 1px 1px 15px #e9d397;
    background-color: rgba(251, 225, 219, 0.4);
    scale: 1.1;
  }
}
