.welcome {
  padding: 60px 100px;
  background-color: white;

  .top-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 100px;
    .heading-wrapper {
      min-width: 355px;
    }
    .content {
      line-height: 1.4;
      p:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .content-wrapper {
    margin-top: 70px;
    .cards-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      flex-wrap: wrap;
      gap: 30px;
    }
  }
}

@media (max-width: 600px) {
  .welcome {
    padding: 20px;
    text-align: center;

    .top-wrapper {
      flex-direction: column;
      gap: 50px;
      .heading-wrapper {
        width: 100%;
      }
      .content {
        line-height: 1.4;
        p:not(:last-child) {
          margin-bottom: 10px;
        }
      }
    }

    .content-wrapper {
      margin-top: 70px;
      .cards-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 30px;
      }
    }
  }
}
