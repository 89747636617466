@font-face {
	font-family: 'tilda-sans';
	src: local("TildaSans-Black"), url("./fonts/Tilda-Sans-Font-Family/TildaSans-Black.woff") format("woff");
   /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
		// url("./fonts/Tilda-Sans-Font-Family/TildaSans-Black.ttf")
		// format("truetype"); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
  }

.heading-wrapper {
  .small-heading {
    color: #FFC107;
    font-size: 20px;
    letter-spacing: 5px;
    margin-bottom: 10px;
  }
  .large-subheading {
    color: #333333;
    font-size: 28px;
    letter-spacing: 3px;
    font-weight: 600;
    line-height: 1.2;
  }
}
