$primary-bg: #ffc80e;
$secondary-bg: #f2f5f9;

$primary-blue: #19244c;

$primary-text: #121212;
$secondary-text: #f6f6f6;
$tertiary-text: #101010;
$grey-text: #1e1e1e;

$white: #ffffff;
$black: #000000;

$icon-primary: #141f41;

$yellow-border: #fbcf3a;

$tab-background: #F8FBFF;
$selected-tab-bg: #ECF3FF;
