.highlight-section {
  padding: 100px 60px;

  .back-btn {
    padding: 5px;
    // background-color: rgb(193, 193, 193);
    font-size: 18px;
    margin-bottom: 20px;
    border-radius: 4px;
    display: inline-block;
  }

  .accordion {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    gap: 50px;
    height: 80vh;
    line-height: 2;
    box-shadow: 1px 1px 15px #e9d397;
    margin-bottom: 50px;
    cursor: pointer;

    &.other {
      flex-direction: row;
      height: 100%;
    }

    &.highlight {
        border: 1px solid black;
    }

    .right {

        .heading-wrapper {
            margin-bottom: 50px;
            text-transform: capitalize;
        }
        
        p {
            font-size: 16px;
        }
    }
  }
}
