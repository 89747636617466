.contact-section {
  position: relative;
  padding: 60px;
  z-index: 1;
  background: url("../../assets/hawan.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  .bg-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .container {
    border-radius: 8px;
    padding: 30px;
    color: #fff;
    backdrop-filter: blur(0.6rem);
    z-index: 10;

    .heading {
      font-size: 38px;
      max-width: 65%;
      margin: auto;
      margin-bottom: 50px;
      font-weight: 400;
      line-height: 1.3;
      // color: #1e3a5f;
    }
    .mail {
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 30px;
    }
    .social-media {
      font-weight: 300;
      line-height: 2;
      cursor: pointer;
    }
    .privacy {
      font-weight: 400;
      margin: 40px 0;
      line-height: 1.6;
    }
    .copyright {
      line-height: 1.6;
      font-weight: 300;
      // color: #333333;
    }
  }
}

@media (max-width: 600px) {
  .contact-section {
    padding: 20px;

    .container {
      padding: 20px;

      .heading {
        font-size: 24px;
        max-width: 100%;

        margin-bottom: 30px;
      }
      .mail {
        font-size: 12px;

        margin-bottom: 30px;
      }
      .social-media {
        font-size: 12px;
      }
      .privacy {
        font-size: 12px;
      }
      .copyright {
        font-size: 12px;
      }
    }
  }
}
